import React, { useEffect, useRef, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import SortIcon from './SortIcon';
import tableResources from './tableResources';
import InfiniteScroll from 'react-infinite-scroll-component';
import './_new-info-box.scss';
import { useSticky } from 'react-table-sticky';
import './_sticky-table.scss';
import { PAGE_SIZE, SELECTION } from '../../constants/table';
import classNames from 'classnames';
import IndeterminateCheckboxCell from './IndeterminateCheckboxCell';
import IndeterminateCheckboxHeader from './IndeterminateCheckboxHeader';
import { getSortOrder } from '../../utilities/sorting';
import PropTypes from 'prop-types';
import SearchBox from '../SearchBox/SearchBox';

const NewTable = forwardRef(function NewTable({
    columns,
    initialData,
    update,
    initialSortBy,
    totalCount,
    updateSelectedItems,
    selectedItems,
    getItemId,
    warningRowCondition,
    errorRowCondition,
    tableClassName,
    textNoDataFound,
    requireSelect,
    tableButton,
    tableRecordsInfo,
    manualSortBy,
    tableIndex
}, externalRef) {
    const ref = externalRef || useRef();
    const scrollableDiv = useRef(null);
    const [items, setItems] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const updateFilteredData = (pageNumber, value) => {
        const page = pageNumber || Math.floor(initialData.size / PAGE_SIZE) + 1;
        if (!sortBy[0]) {
            sortBy[0] = initialSortBy;
        }
        update(page, sortBy[0].id, getSortOrder(sortBy[0].desc), value);
    };

    const getRowTheme = (row) => {
        if (errorRowCondition && errorRowCondition(row)) {
            return 'red';
        }
        if (warningRowCondition && warningRowCondition(row)) {
            return 'yellow';
        }
        return 'white';
    };

    const defaultColumn = useMemo(
        () => ({
            minWidth: 42,
        }),
        []
    );
    const data = useMemo(() => items, [items]);
    const caseInsensitiveAlphaNumericSort = (prev, curr, columnId) => {
        if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
            return 1;
        } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
            return -1;
        } else {
            return 0;
        }
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        isAllRowSelected,
        getToggleAllRowsSelectedProps,
        state: { sortBy, selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            manualSortBy,
            getRowId: getItemId,
            autoResetSelectedRows: false,
            sortTypes: { caseInsensitiveAlphaNumeric: caseInsensitiveAlphaNumericSort },
            initialState: {
                sortBy: [
                    initialSortBy
                ]
            }
        },
        useSortBy,
        useRowSelect,
        useSticky,
        (hooks) => {
            requireSelect && hooks.visibleColumns.push(columns => [
                {
                    id: SELECTION,
                    sticky: 'left',
                    canSort: true,
                    width: 42,
                    maxWidth: 42,
                    left: 0,
                    Header: IndeterminateCheckboxHeader,
                    Cell: IndeterminateCheckboxCell,
                    getProps: () => ({
                        getToggleAllRowsSelectedProps
                    }),
                },
                ...columns
            ]);
        }
    );
    useEffect(() => {
        if (data.length > 0) {
            scrollableDiv.current.scrollTop = 0;
            if (sortBy[0] != null && sortBy[0].id === SELECTION) {
                sortBySelection(true);
                return;
            }
            updateFilteredData(1);
        }
    }, [sortBy]);
    useEffect(() => {
        if (initialData.size === PAGE_SIZE) {
            scrollableDiv.current.scrollTop = 0;
        }
        setItems(initialData.toJS());
        if (sortBy[0] && sortBy[0].id === SELECTION) {
            sortBySelection(false);
        }
    }, [initialData]);
    useEffect(() => {
        const selectedRowsToAdd = selectedFlatRows.filter(x => !selectedRows.find(row => row.id === x.id));
        const newSelectedRows = selectedRows.filter(x => Object.keys(selectedRowIds).map(Number)
            .find(sr => sr === x.id)).concat(selectedRowsToAdd);
        setSelectedRows(newSelectedRows);
        updateSelectedItems(newSelectedRows.map(row => row.original));
    }, [selectedRowIds]);

    const sortBySelection = (isSortingChange) => {
        if (selectedItems.length === 0 || isAllRowSelected) {
            return;
        }
        let pageNumber = Math.floor(items.length / PAGE_SIZE) + 1;
        if ((!isSortingChange && initialData.size === PAGE_SIZE) || isSortingChange) {
            scrollableDiv.current.scrollTop = 0;
            pageNumber = 1;
        }
        if (sortBy[0].desc) {
            setItems(initialData.toJS().sort((first, second) => {
                const isFirstSelected = selectedRowIds[getItemId(first)] || false;
                const isSecondSelected = selectedRowIds[getItemId(second)] || false;
                if (isFirstSelected > isSecondSelected) return 1;
                if (isFirstSelected < isSecondSelected) return -1;
                return 0;
            }));
        }
        else {
            if (selectedItems.length >= PAGE_SIZE * pageNumber) {
                setItems(selectedItems.slice(0, PAGE_SIZE * pageNumber));
            }
            else if (selectedItems.length + initialData.size >= PAGE_SIZE * pageNumber) {
                setItems(selectedItems.concat(initialData.toJS().filter(x => !selectedItems.find(si => getItemId(si) === getItemId(x)))));
            }
            else {
                update();
            }
        }
    };
    useImperativeHandle(ref, () => {
        return { refreshTable: () => updateFilteredData(1) };
    });
    const scrollableDivID = `planReimbursementsGroup_${tableIndex}`;
    return (
        <div className="new-info-box">
            <div className="new-info-box__table-container">
                <div className={rows.length > 0 ? `new-info-box__table-container-scrollable ${tableClassName}` : 'new-info-box__table-container-nonscrollable'} id={scrollableDivID}
                    ref={scrollableDiv}
                >
                    <InfiniteScroll
                        dataLength={rows.length}
                        next={updateFilteredData}
                        hasMore={totalCount > data.length}
                        scrollableTarget={scrollableDivID}
                        scrollThreshold={0.99}
                    >
                        <table {...getTableProps()} className="new-info-box__table">
                            <thead>
                                {headerGroups.map((headerGroup, i) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={`head-row_${i}`}>
                                        {headerGroup.headers.map((column, i) => (
                                            <th
                                                key={`head-cell_${i}`}
                                                {...column.getHeaderProps()}
                                                className={classNames([
                                                    'new-info-box__table-cell',
                                                    { 'new-info-box__table-cell_head_sticky-thead': column.sticky != null },
                                                ])}
                                                style={{ minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth, left: column.left, right: column.right }}
                                            >
                                                <div className="new-info-box__table-cell-head-content">
                                                    <div {...(column.id !== SELECTION && column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                    </div>
                                                    {!column.disableSortBy && (
                                                        <div className="new-info-box__table-cell-sort-icon-container">{column.canSort ? <SortIcon isSortedDesc={column.isSortedDesc} {...column.getSortByToggleProps()} /> : ''}</div>
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                {headerGroups.map((headerGroup, i) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={`head-row_${i}`}>
                                        {headerGroup.headers.map((column, i) => (
                                            <th
                                                key={`head-cell_${i}`}
                                                {...column.getHeaderProps()}
                                                style={{ minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth, left: column.left, right: column.right }}
                                            >
                                                {column.searchBox
                                                    ? <SearchBox className={classNames(['new-info-box__search-box'])}
                                                        placeholder="Search"
                                                        onChange={value => updateFilteredData(1, value)}
                                                        clearable={column.clearable}
                                                        validationTooltip={column.validationTooltip}
                                                      />
                                                    : <></>}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className={`new-info-box__table-row new-info-box__table-row__${getRowTheme(row)}`}
                                            key={`body-row_${i}`}
                                        >
                                            {row.cells.map((cell, i) => {
                                                return (
                                                    <td
                                                        key={`body-cell_${i}`}
                                                        {...cell.getCellProps()}
                                                        style={{ minWidth: cell.column.minWidth, width: cell.column.width, maxWidth: cell.column.maxWidth, left: cell.column.left, right: cell.column.right }}
                                                        className={classNames([
                                                            'new-info-box__table-cell',
                                                            { 'new-info-box__table-cell_sticky': cell.column.sticky != null },
                                                        ])}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
            {rows.length > 0 ?
                <div className="new-info-box__table-footer">
                    <div className="new-info-box__table-records-count-container">
                        <div className="new-info-box__table-records-count">Total records: {totalCount}</div>
                        {requireSelect && <div className="new-info-box__table-records-count">Selected: {Object.keys(selectedRowIds).length}</div>}
                        {tableRecordsInfo && <div>{tableRecordsInfo}</div>}
                    </div>
                    {tableButton && <div className="new-info-box__table-buttons-container">
                        {tableButton}
                    </div>}
                </div>
                :
                <div className={`new-info-box__table-text-container ${tableClassName}`}>
                    {textNoDataFound}
                </div>}
        </div >
    );
});

NewTable.defaultProps = {
    textNoDataFound: tableResources.textNoDataFound,
    updateSelectedItems: () => { },
    update: () => { },
    manualSortBy: true,
    tableIndex: 0
};

NewTable.propTypes = {
    update: PropTypes.func.isRequired,
    initialData: PropTypes.object.isRequired,
    updateSelectedItems: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    initialSortBy: PropTypes.object.isRequired,
    getItemId: PropTypes.func.isRequired,
    tableButtons: PropTypes.node,
    tableRecordsInfo: PropTypes.node,
    manualSortBy: PropTypes.bool
};
export default NewTable;
