import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    setMemberToDeactivateMembership,
    setMemberToDelete,
} from '../employeeManagementActions';
import MaterialIcon from '../../../../components/MaterialIcon/MaterialIcon';
import ToolTip from '../../../../components/controls/ToolTip';
import keyfobsAndEmployeeResources from './keyfobsAndEmployeesListResources';
import { useFlags } from 'launchdarkly-react-client-sdk';
import invitationStatuses from '../../../../enums/invitationStatuses';

function KeyfobActionsCell({ value, setMemberToDeactivate, setMemberToDelete }) {
    const deleteMemberHandler = () => {
        setMemberToDelete(value.row);
    };
    const deactivateHandler = () => {
        setMemberToDeactivate(value.row);
    };
    const { gmrAddProgram } = useFlags();
    return (
        value.isVisible ?
            <div className="keyfobs-employees-list__button-action-container">
                {gmrAddProgram ?
                    <></>
                    : <ToolTip title={keyfobsAndEmployeeResources.buttonDeactivate} arrow placement="top">
                        <div>
                            <button className="keyfobs-employees-list__button-action" onClick={deactivateHandler}
                                disabled={!value.row.memberFirstName}
                            >
                                <MaterialIcon className="keyfobs-employees-list__button-action-icon" icon="person_off"
                                    variant={'filled'}
                                />
                            </button>
                        </div>
                    </ToolTip>}
                <ToolTip title={keyfobsAndEmployeeResources.buttonDelete} arrow
                    placement="top"
                >
                    <button className="keyfobs-employees-list__button-action" onClick={deleteMemberHandler}
                        disabled={value.row.invitationStatus === invitationStatuses.enrolledWaitingForPayment || value.row.invitationStatus === invitationStatuses.waitingToDelete}
                    >
                        <MaterialIcon className="keyfobs-employees-list__button-action-icon" icon="delete"
                            variant={'filled'}
                        />
                    </button>
                </ToolTip>
            </div>
            : <></>
    );
}

KeyfobActionsCell.propTypes = {
    value: PropTypes.object.isRequired,
    setMemberToDeactivate: PropTypes.func.isRequired,
    setMemberToDelete: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    setMemberToDeactivate: setMemberToDeactivateMembership,
    setMemberToDelete
};

export default connect(null, mapDispatchToProps)(KeyfobActionsCell);