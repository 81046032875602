import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmPopup from '../../../../components/Popups/ConfirmAlert/ConfirmPopup';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import ReplaceKeyfobActionsCell from './KeyfobActionsCell';
import NewTable from '../../../../components/NewInfoBox/NewTable';
import keyfobsAndEmployeeResources from './keyfobsAndEmployeesListResources';
import { PAGE_SIZE } from '../../../../constants/table';
import {
    getKeyfobsList,
    getIsReassignKeyfobFeatureEnabled,
    resetMemberToDeactivateMembership,
    deleteKeyfob,
    deactivateMembership,
    reassignKeyfob,
    resetKeyfobToReassign,
    resetKeyfobIdToDelete,
    downloadKeyfobsAndEmployees,
    setKeyfobToReassign,
    getMembersWaitingToInviteCount,
    getProgramPaymentTypeID,
    deleteMemberGroupMembership,
    updateMember
} from '../employeeManagementActions';
import selectors from './keyfobsAndEmployeesListSelectors';
import NewEditableCell from '../../../../components/NewInfoBox/NewEditableCell';
import keyfobStatuses, { keyfobStatusesColorsMap } from '../../../../enums/keyfobStatuses';
import preferredAccessTypes from '../../../../enums/preferredAccessTypes';
import invitationStatuses, { invitationStatusesColorsMap, invitationStatusesNamesMap } from '../../../../enums/invitationStatuses';
import * as validationRules from './KeyfobReplacementConfirmationForm/keyfobReplacementConfirmationFormValidation';
import './_keyfobs-employees-list.scss';
import BulletCell from '../../../../components/NewInfoBox/BulletCell';
import TooltipCell from '../../../../components/NewInfoBox/TooltipCell';
import Button from '../../../../components/controls/Button';
import BulletCaption from '../../../../components/BulletCaption/BulletCaption';
import MaterialIcon from '../../../../components/MaterialIcon/MaterialIcon';
import { isDesktopSize } from '../../../../utilities/windowSize';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as emailValidationRules from '../../../Shared/Validations/emailValidations';

const KeyfobsAndEmployeesList = forwardRef(function KeyfobsAndEmployeesList({
    partnerId,
    keyfobs,
    getKeyfobsList,
    getIsReassignKeyfobFeatureEnabled,
    memberToDeactivateMembership,
    isReassignKeyfobFeatureEnabled,
    deleteKeyfobConfirmationMessage,
    deactivationConfirmationPopupMessage,
    deleteMemberConfirmationMessage,
    deleteMemberConfirmationTitle,
    isConfirmationPopupVisible,
    resetMemberToDeactivate,
    deleteKeyfob,
    deactivateMembership,
    deleteMemberGroupMembership,
    reassignKeyfob,
    resetKeyfobToReassign,
    isAdmin,
    keyfobIdToDelete,
    memberToDelete,
    resetKeyfobIdToDelete,
    isDeleteKeyfobConfirmationPopupVisible,
    downloadKeyfobsAndEmployees,
    totalCount,
    getMembersWaitingToInviteCount,
    membersWaitingToInviteCount,
    getProgramPaymentTypeID,
    programPaymentTypeID,
    isPartnerManager,
    updateMember,
}, externalRef) {
    const ref = externalRef || useRef();
    const { gmrAddProgram } = useFlags();
    const isDesktop = isDesktopSize();
    const tableRef = useRef();
    const refreshGrid = () => {
        tableRef.current.refreshTable();
    };
    useEffect(() => {
        refreshGrid();
        getIsReassignKeyfobFeatureEnabled();
    }, []);
    useEffect(() => {
        if (partnerId && gmrAddProgram) {
            getMembersWaitingToInviteCount(partnerId);
            getProgramPaymentTypeID(partnerId);
        }
    }, [partnerId]);
    const exportToExcel = () => {
        if (partnerId) {
            downloadKeyfobsAndEmployees(partnerId);
        }
    };

    const deleteKeyfobHandler = () => {
        if (gmrAddProgram) {
            deleteMemberGroupMembership(memberToDelete, programPaymentTypeID).then(() => {
                refreshGrid();
            });
        } else {
            deleteKeyfob(keyfobIdToDelete).then(() => {
                refreshGrid();
            });
        }
        resetKeyfobIdToDelete();
    };

    const deactivateHandler = () => {
        resetMemberToDeactivate();
        deactivateMembership(memberToDeactivateMembership.facilityMemberID).then(() => {
            refreshGrid();
        });
    };

    const newFacilityIDNumberSubmitHandler = (value, oldValue) => {
        resetKeyfobToReassign();
        const reassignKeyfobParams = {
            oldFacilityMemberID: oldValue,
            newFacilityMemberID: value,
            partnerID: partnerId,
        };
        reassignKeyfob(reassignKeyfobParams).finally(() => {
            refreshGrid();
        });
    };

    const newEmailSubmitHandler = (value, oldValue, member) => {
        member.memberEmail = value;
        updateMember(member).finally(refreshGrid);
    };

    const updateHandler = (pageNumber, sortColumn, sortOrder, filter) => {
        if (partnerId) {
            getKeyfobsList(pageNumber, PAGE_SIZE, sortColumn, sortOrder, partnerId, filter);
        }
    };

    const columns = useMemo(() => {
        return gmrAddProgram ?
            [
                {
                    id: 'memberFirstName',
                    Header: keyfobsAndEmployeeResources.labelEmployeeName,
                    accessor: (x) => {
                        return x.memberFirstName ? `${x.memberFirstName} ${x.memberLastName}` : '';
                    },
                    width: 200,
                    minWidth: 200,
                    sticky: isDesktop ? 'left' : null,
                    left: 0,
                    searchBox: true,
                    clearable: false,
                    validationTooltip: keyfobsAndEmployeeResources.searchValidation,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelEmail,
                    accessor: 'memberEmail',
                    Cell: NewEditableCell,
                    getProps: row => ({
                        updateCellHandler: newEmailSubmitHandler,
                        disabled: false,
                        maxWidth: '150px',
                        isEditButtonEnabled: (isAdmin || isPartnerManager) && (row.original.invitationStatus === invitationStatuses.waitingToInvite || row.original.invitationStatus === invitationStatuses.invited),
                        editTooltip: keyfobsAndEmployeeResources.buttonEditEmail,
                        validateCellRules: [
                            emailValidationRules.emailRequired(keyfobsAndEmployeeResources.labelEmail),
                            emailValidationRules.emailRegex(keyfobsAndEmployeeResources.labelEmail),
                            emailValidationRules.emailMaxLength(keyfobsAndEmployeeResources.labelEmail)
                        ]
                    }),
                    width: 200,
                    minWidth: 200,
                    sticky: isDesktop ? 'left' : null,
                    left: 200
                },
                {
                    Header: keyfobsAndEmployeeResources.labelKeyfobStatus,
                    accessor: 'invitationStatusDescription',
                    Cell: BulletCell,
                    getProps: row => ({
                        theme: invitationStatusesColorsMap[row.original.invitationStatus]
                    }),
                    width: 190,
                    minWidth: 190,
                    sticky: isDesktop ? 'left' : null,
                    left: 384
                },
                {
                    Header: keyfobsAndEmployeeResources.labelDateOfBirth,
                    accessor: 'memberDateOfBirth',
                    width: 98,
                    minWidth: 98,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelProgramEnrollmentDate,
                    accessor: 'programEnrollmentDate',
                    width: 135,
                    minWidth: 135,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelLastMonthVisitsCount,
                    accessor: 'lastMonthUsagesCount',
                    width: 86,
                    minWidth: 86,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelFacilityID,
                    accessor: 'facilityIDNumber',
                    width: 100,
                    minWidth: 100
                },
                {
                    Header: keyfobsAndEmployeeResources.labelFacilityName,
                    accessor: 'facilityName',
                    Cell: TooltipCell,
                    width: 132,
                    minWidth: 132
                },
                {
                    Header: keyfobsAndEmployeeResources.labelKeyfob,
                    accessor: (x) => {
                        return x.preferredAccessType === preferredAccessTypes.digitalKey ? keyfobsAndEmployeeResources.labelKeyfobAccessPass : x.facilityMemberID;
                    },
                    Cell: NewEditableCell,
                    getProps: row => ({
                        updateCellHandler: newFacilityIDNumberSubmitHandler,
                        disabled: row.original.keyfobStatusID === keyfobStatuses.onHold,
                        isEditButtonEnabled: row.original.preferredAccessType === preferredAccessTypes.physicalKey && isAdmin && isReassignKeyfobFeatureEnabled && row.original.invitationStatus === invitationStatuses.activated,
                        isReassignKeyfobFeatureEnabled,
                        isVisible: true,
                        editTooltip: keyfobsAndEmployeeResources.buttonReassign,
                        validateCellRules: [
                            validationRules.keyfobRequired,
                            validationRules.keyfobMinMaxLength,
                            validationRules.keyfobValidRadixNumber,
                            validationRules.newKeyfobNotEqualToOldKeyfob
                        ]
                    }),
                    width: 135,
                    minWidth: 135,
                    maxWidth: 135,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelMobilePhone,
                    accessor: 'memberPhoneNumber',
                    Cell: TooltipCell,
                    width: 136,
                    minWidth: 136
                },
                {
                    Header: keyfobsAndEmployeeResources.labelActions,
                    id: 'actions',
                    accessor: (row) => { return { row, isVisible: true }; },
                    Cell: ReplaceKeyfobActionsCell,
                    minWidth: 88,
                    width: 88,
                    sticky: 'right',
                    right: 0,
                    disableSortBy: true
                }
            ]
            : [
                {
                    Header: keyfobsAndEmployeeResources.labelKeyfob,
                    accessor: 'facilityMemberID',
                    Cell: NewEditableCell,
                    getProps: row => ({
                        updateCellHandler: newFacilityIDNumberSubmitHandler,
                        disabled: row.original.keyfobStatusID === keyfobStatuses.onHold,
                        isEditButtonEnabled: isAdmin && isReassignKeyfobFeatureEnabled,
                        isReassignKeyfobFeatureEnabled,
                        isVisible: true,
                        editTooltip: keyfobsAndEmployeeResources.buttonReassign,
                        validateCellRules: [
                            validationRules.keyfobRequired,
                            validationRules.keyfobMinMaxLength,
                            validationRules.keyfobValidRadixNumber,
                            validationRules.newKeyfobNotEqualToOldKeyfob
                        ]
                    }),
                    width: 135,
                    minWidth: 135,
                    maxWidth: 135,
                    sticky: 'left',
                    left: 0,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelKeyfobStatus,
                    accessor: 'keyfobStatusDisplayName',
                    Cell: BulletCell,
                    getProps: row => ({
                        theme: keyfobStatusesColorsMap[row.original.keyfobStatusID]
                    }),
                    width: 190,
                    minWidth: 190,
                    sticky: isDesktop ? 'left' : null,
                    left: 135,
                },
                {
                    id: 'memberFirstName',
                    Header: keyfobsAndEmployeeResources.labelEmployeeName,
                    accessor: (x) => {
                        return x.memberFirstName ? `${x.memberFirstName} ${x.memberLastName}` : '';
                    },
                    width: 200,
                    minWidth: 200,
                    sticky: isDesktop ? 'left' : null,
                    left: 325
                },
                {
                    Header: keyfobsAndEmployeeResources.labelDateOfBirth,
                    accessor: 'memberDateOfBirth',
                    width: 98,
                    minWidth: 98,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelProgramEnrollmentDate,
                    accessor: 'programEnrollmentDate',
                    width: 135,
                    minWidth: 135,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelLastMonthUsageCount,
                    accessor: 'lastMonthUsagesCount',
                    width: 86,
                    minWidth: 86,
                },
                {
                    Header: keyfobsAndEmployeeResources.labelFacilityID,
                    accessor: 'facilityIDNumber',
                    width: 100,
                    minWidth: 100
                },
                {
                    Header: keyfobsAndEmployeeResources.labelFacilityName,
                    accessor: 'facilityName',
                    Cell: TooltipCell,
                    width: 132,
                    minWidth: 132
                },
                {
                    Header: keyfobsAndEmployeeResources.labelMobilePhone,
                    accessor: 'memberPhoneNumber',
                    Cell: TooltipCell,
                    width: 136,
                    minWidth: 136
                },
                {
                    Header: keyfobsAndEmployeeResources.labelEmailAddress,
                    accessor: 'memberEmail',
                    Cell: TooltipCell,
                    width: 184,
                    minWidth: 184
                },
                {
                    Header: keyfobsAndEmployeeResources.labelActions,
                    id: 'actions',
                    accessor: (row) => { return { row, isVisible: true }; },
                    Cell: ReplaceKeyfobActionsCell,
                    minWidth: 88,
                    width: 88,
                    sticky: 'right',
                    right: 0,
                    disableSortBy: true
                }
            ];
    }, [keyfobsAndEmployeeResources, isAdmin, isReassignKeyfobFeatureEnabled, isDesktop, gmrAddProgram]);

    const getMemberId = (item) => {
        return gmrAddProgram ? item.memberId : item.facilityMemberID;
    };

    const waitingToInviteCount =
        <BulletCaption size="medium" color={invitationStatusesColorsMap[invitationStatuses.waitingToInvite]}
            text={`${invitationStatusesNamesMap[invitationStatuses.waitingToInvite]}: ${membersWaitingToInviteCount}`}
            textColor="blue"
        />;
    useImperativeHandle(ref, () => {
        return { refreshGrid: () => refreshGrid() };
    });
    return <div className="keyfobs-employees-list">
        {gmrAddProgram ?
            <div className="keyfobs-employees-list__button-container--left">
                <Button className="btn_new btn_new--white"
                    onClick={exportToExcel}
                >
                    <MaterialIcon className="btn_new-icon" icon="download" />
                </Button>
            </div>
            : <div className="keyfobs-employees-list__title-container">
                <h2 className="keyfobs-employees-list__title">{keyfobsAndEmployeeResources.titleKeyfobsList}</h2>
                <div className="keyfobs-employees-list__button-container">
                    <Button className="btn_new btn_new--white"
                        onClick={exportToExcel}
                    >
                        <MaterialIcon className="btn_new-icon btn_new-icon--left" icon="download" />
                        {keyfobsAndEmployeeResources.buttonDownload}
                    </Button>
                </div>
            </div>
        }
        <InfoBox>
            <ConfirmPopup showPopup={isConfirmationPopupVisible} body={<div className="message">{deactivationConfirmationPopupMessage}</div>}
                onConfirm={deactivateHandler}
                onCancel={resetMemberToDeactivate}
                labelOk={keyfobsAndEmployeeResources.buttonDeactivate}
            />
            <ConfirmPopup showPopup={isDeleteKeyfobConfirmationPopupVisible} body={<div className="message">{gmrAddProgram ? deleteMemberConfirmationMessage : deleteKeyfobConfirmationMessage}</div>}
                title={gmrAddProgram ? deleteMemberConfirmationTitle : null}
                onConfirm={deleteKeyfobHandler}
                onCancel={resetKeyfobIdToDelete}
                labelOk={keyfobsAndEmployeeResources.buttonDelete}
            />
            <NewTable columns={columns} initialData={keyfobs}
                update={updateHandler}
                initialSortBy={{
                    id: gmrAddProgram ? 'memberFirstName' : 'facilityMemberID',
                    desc: false
                }}
                totalCount={totalCount}
                getItemId={getMemberId}
                ref={tableRef}
                tableRecordsInfo={gmrAddProgram ? waitingToInviteCount : null}
            />
        </InfoBox>
    </div >;
});

KeyfobsAndEmployeesList.propTypes = {
    partnerId: PropTypes.number,
    keyfobs: PropTypes.object.isRequired,
    getKeyfobsList: PropTypes.func.isRequired,
    getIsReassignKeyfobFeatureEnabled: PropTypes.func.isRequired,
    memberToDeactivateMembership: PropTypes.object,
    keyfobToReassign: PropTypes.object,
    keyfobIdToDelete: PropTypes.number,
    memberToDelete: PropTypes.object,
    isReassignKeyfobFeatureEnabled: PropTypes.bool.isRequired,
    deactivationConfirmationPopupMessage: PropTypes.string,
    isConfirmationPopupVisible: PropTypes.bool.isRequired,
    reassignConfirmationPopupMessage: PropTypes.string,
    deleteKeyfobConfirmationMessage: PropTypes.string,
    deleteMemberConfirmationMessage: PropTypes.string,
    deleteMemberConfirmationTitle: PropTypes.string,
    isReassignConfirmationPopupVisible: PropTypes.bool.isRequired,
    resetMemberToDeactivate: PropTypes.func.isRequired,
    deactivateMembership: PropTypes.func.isRequired,
    reassignKeyfob: PropTypes.func.isRequired,
    resetKeyfobToReassign: PropTypes.func.isRequired,
    deleteKeyfob: PropTypes.func.isRequired,
    deleteMemberGroupMembership: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isDeleteKeyfobConfirmationPopupVisible: PropTypes.bool.isRequired,
    resetKeyfobIdToDelete: PropTypes.func.isRequired,
    downloadKeyfobsAndEmployees: PropTypes.func.isRequired,
    setKeyfobToReassign: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
    membersWaitingToInviteCount: PropTypes.number,
    getMembersWaitingToInviteCount: PropTypes.func.isRequired,
    programPaymentTypeID: PropTypes.number,
    isPartnerManager: PropTypes.bool.isRequired,
    updateMember: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getKeyfobsList,
    getIsReassignKeyfobFeatureEnabled,
    resetMemberToDeactivate: resetMemberToDeactivateMembership,
    deactivateMembership,
    deleteKeyfob,
    reassignKeyfob,
    resetKeyfobToReassign,
    resetKeyfobIdToDelete,
    downloadKeyfobsAndEmployees,
    setKeyfobToReassign,
    getMembersWaitingToInviteCount,
    getProgramPaymentTypeID,
    deleteMemberGroupMembership,
    updateMember
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(KeyfobsAndEmployeesList);
